
import { defineComponent } from "vue";

export default defineComponent({
  name: "Widget14",
  props: {
    widgetClasses: {type: String, default: ""},
    widgetColor: {type: String, default: ""},
  },
});
