
import { defineComponent } from "vue";
import Dropdown2 from "@/components/dropdown/Dropdown2.vue";

export default defineComponent({
  name: "Widget1",
  components: {
    Dropdown2,
  },
  props: {
    widgetClasses: {type: String, default: ""},
    color: {type: String, default: ""},
    image: {type: String, default: ""},
    title: {type: String, default: ""},
    date: {type: String, default: ""},
    progress: {type: String, default: ""},
  },
});
